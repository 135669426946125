import { AppointmentFormBarbersServicesAndClientsQuery } from '@app/graphql/generated';
import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';
import { AppointmentData } from '../Appointments.types';
import { getClientLabel, sortClients } from '@app/utils/client';

type Options = { label: string; value: number }[];
interface formVisibleState {
  formVisible: boolean;
  editingAppointment?: AppointmentData;
  barberOptions: Options;
  clientOptions: Options;
  servicesOptions: Options;
}

const initialState: formVisibleState = {
  barberOptions: [],
  clientOptions: [],
  editingAppointment: undefined,
  formVisible: false,
  servicesOptions: [],
};

export const setFormVisible = createAction<PrepareAction<boolean>>(
  'formVisible/setFormVisible',
  (formVisible: boolean) => {
    return {
      payload: formVisible,
    };
  },
);

export const setFormBarbersAndServices = createAction<
  PrepareAction<{
    barbers: Options;
    services: Options;
    clients: Options;
  }>
>('appointmentForm/setFormBarbersAndServices', (data: AppointmentFormBarbersServicesAndClientsQuery) => {
  return {
    payload: {
      barbers: data?.barbers.map((barber) => {
        return {
          label: `${barber.name}`,
          value: barber.id,
        };
      }),
      clients: data?.clients.map(getClientLabel),
      services: data?.services.map((service) => {
        return {
          label: `${service.name}`,
          value: service.id,
        };
      }),
    },
  };
});

export const appointmentFormSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(setFormVisible, (state, action) => {
      state.formVisible = action.payload;
    });

    builder.addCase(setFormBarbersAndServices, (state, action) => {
      state.barberOptions = action.payload.barbers;
      state.servicesOptions = action.payload.services;
      state.clientOptions = sortClients(action.payload.clients);
    });
  },
  initialState,
  name: 'formVisible',
  reducers: {},
});

export default appointmentFormSlice.reducer;
