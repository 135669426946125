import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '../buttons/Button/Button';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Back = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleBack = () => {
    // If we have state in location, we came from somewhere specific
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <Button onClick={handleBack} icon={<ArrowLeftOutlined />} type="text" style={{ width: 'fit-content' }}>
        {t('components.back.title')}
      </Button>
      <Outlet />
    </>
  );
};
