import { DayView, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { FloatingPortal, offset, shift, useFloating } from '@floating-ui/react';
import React, { memo, useMemo, useRef, useState } from 'react';

import { AppointmentBarberWorkTimesQuery, AppointmentFormBarbersServicesAndClientsQuery } from '@app/graphql/generated';
import moment from 'moment';

const STYLES = {
  freeDay: {
    backgroundColor: 'red',
  },
  outsideWorkingHours: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
} as const;

const isTimeWithinWorkHours = (
  cellStartDate: moment.Moment,
  cellEndTime: moment.Moment,
  workTimes: AppointmentFormBarbersServicesAndClientsQuery['barbers'][number]['barberWorkTimes'],
) => {
  for (const workTime of workTimes) {
    const workStartTime = moment(workTime.start).set({
      date: cellStartDate.date(),
      milliseconds: 0,
      month: cellStartDate.month(),
      seconds: 0,
      year: cellStartDate.year(),
    });
    const workEndTime = moment(workTime.end).set({
      date: cellEndTime.date(),
      milliseconds: 0,
      month: cellEndTime.month(),
      seconds: 0,
      year: cellEndTime.year(),
    });

    if (cellStartDate.isSameOrAfter(workStartTime) && cellEndTime.isSameOrBefore(workEndTime)) {
      return true;
    }
  }
  return false;
};

export const getViewTimeCell = ({
  barberWorkTimeOverridesData,
  barbers,
  dayView,
}: {
  barberWorkTimeOverridesData?: AppointmentBarberWorkTimesQuery['barberWorkTimeOverrides'];
  dayView?: boolean;
  barbers?: AppointmentFormBarbersServicesAndClientsQuery['barbers'];
}) => {
  return memo((props: WeekView.TimeTableCellProps) => {
    const barberId = props.groupingInfo?.[0].id;

    const barber = useMemo(() => barbers?.find((b) => b.id === barberId), [barberId]);

    const { isWithinWorkHours, isFreeDay, cellIsInThePast } = useMemo(() => {
      const cellStartDate = moment(props.startDate).set({ millisecond: 0, second: 0 });
      const cellEndTime = moment(props.endDate).set({ millisecond: 0, second: 0 });
      const now = moment();

      const workTimes = barber?.barberWorkTimes.filter(
        (workTime) => workTime.barberId === barberId && workTime.dayOfWeek === props.startDate?.getDay(),
      );

      const workTimeOverrides = barberWorkTimeOverridesData?.filter(
        (workTimeOverride) =>
          workTimeOverride.barberId === barberId &&
          moment(workTimeOverride.date).isSame(moment(props.startDate), 'date'),
      );

      let isWithinWorkHours = false;
      let isFreeDay = false;

      if (cellStartDate.isAfter(now)) {
        if (workTimeOverrides?.length) {
          for (const workTimeOverride of workTimeOverrides) {
            const workStartTime = moment(workTimeOverride.start).set({
              date: cellStartDate.date(),
              milliseconds: 0,
              month: cellStartDate.month(),
              year: cellStartDate.year(),
            });
            const workEndTime = moment(workTimeOverride.end).set({
              date: cellEndTime.date(),
              milliseconds: 0,
              month: cellEndTime.month(),
              year: cellEndTime.year(),
            });

            isWithinWorkHours = cellStartDate.isSameOrAfter(workStartTime) && cellEndTime.isSameOrBefore(workEndTime);

            if (isWithinWorkHours) {
              break;
            }
          }
        } else if (workTimes?.length) {
          isWithinWorkHours = isTimeWithinWorkHours(cellStartDate, cellEndTime, workTimes);
        } else {
          isWithinWorkHours = false;
        }
      }
      if (workTimeOverrides?.length) {
        for (const workTimeOverride of workTimeOverrides) {
          if (workTimeOverride.isFreeDay && moment(workTimeOverride.date).isSame(cellStartDate, 'date')) {
            isFreeDay = true;
            break;
          }
        }
      }

      return {
        cellIsInThePast: cellStartDate.isBefore(now),
        isFreeDay,
        isWithinWorkHours,
      };
    }, [props.startDate, props.endDate, barber?.barberWorkTimes, barberId]);

    const tooltipContent = useMemo(
      () =>
        barber
          ? `${barber.name}\n${moment(props.startDate).format('HH:mm')} - ${moment(props.endDate).format('HH:mm')}`
          : '',
      [barber, props.startDate, props.endDate],
    );

    const cellStyle = useMemo(
      () => (isFreeDay ? STYLES.freeDay : !isWithinWorkHours ? STYLES.outsideWorkingHours : undefined),
      [isFreeDay, isWithinWorkHours],
    );

    if (dayView) {
      return (
        <TimeCellTooltip tooltipContent={tooltipContent}>
          <DayView.TimeTableCell {...props} style={cellStyle} isShaded={cellIsInThePast && !isFreeDay} />
        </TimeCellTooltip>
      );
    }

    return <WeekView.TimeTableCell {...props} style={cellStyle} isShaded={cellIsInThePast && !isFreeDay} />;
  });
};

const TimeCellTooltip = memo(
  ({ tooltipContent, children }: { tooltipContent: string; children: React.ReactElement }) => {
    const [isOpen, setIsOpen] = useState(false);
    const cellRef = useRef<HTMLElement | null>(null);

    const { refs, floatingStyles } = useFloating({
      elements: {
        reference: cellRef.current ?? undefined,
      },
      middleware: [offset(10), shift()],
      open: isOpen,
      placement: 'top',
    });

    const handleMouseEnter = (event: React.MouseEvent) => {
      cellRef.current = event.currentTarget as HTMLElement;
      setIsOpen(true);
    };

    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    // Clone the child element with mouse handlers but without ref
    const enhancedChild = React.cloneElement(children, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    });

    return (
      <>
        {enhancedChild}
        {isOpen && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                background: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '4px',
                color: 'white',
                fontSize: '14px',
                padding: '4px 8px',
                whiteSpace: 'pre-line',
                zIndex: 1000,
              }}
            >
              {tooltipContent}
            </div>
          </FloatingPortal>
        )}
      </>
    );
  },
);
