/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { SortOrder } from '@app/graphql/generated';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const ROUTES = {
  ADDITIONAL_SERVICES: {
    children: {
      CREATE: {
        navigateTo: '/additional-services/create',
        route: 'create',
      },
      EDIT: {
        navigateTo: (id: number) => `/additional-services/${id}`,
        route: ':id',
      },
    },
    navigateTo: '/additional-services',
    route: 'additional-services',
  },
  APPOINTMENTS: {
    navigateTo: '/',
    route: '/',
  },
  BARBERS: {
    children: {
      CREATE: {
        navigateTo: '/barbers/create',
        route: 'create',
      },
      EDIT: {
        children: {
          PERSONAL_INFO: {
            navigateTo: (id: number) => `/barbers/${id}`,
            route: '',
          },
          SECURITY_SETTINGS: {
            navigateTo: (id: number) => `/barbers/${id}/security-settings`,
            route: 'security-settings',
          },
          SERVICES: {
            navigateTo: (id: number) => `/barbers/${id}/services`,
            route: 'services',
          },
          WORK_TIMES: {
            navigateTo: (id: number) => `/barbers/${id}/work-times`,
            route: 'work-times',
          },
          WORK_TIMES_OVERRIDES: {
            navigateTo: (id: number) => `/barbers/${id}/work-times-overrides`,
            route: 'work-times-overrides',
          },
        },
        navigateTo: (id: number) => `/barbers/${id}`,
        route: ':id',
      },
    },
    navigateTo: '/barbers',
    route: 'barbers',
  },
  CLIENTS: {
    children: {
      CREATE: {
        navigateTo: '/clients/create',
        route: 'create',
      },
      EDIT: {
        children: {
          APPOINTMENTS: {
            navigateTo: (id: number) => `/clients/${id}/appointments`,
            route: 'appointments',
          },
          FAVOURITES: {
            navigateTo: (id: number) => `/clients/${id}/favourites`,
            route: 'favourites',
          },
          PENALTY: {
            navigateTo: (id: number) => `/clients/${id}/penalty`,
            route: 'penalty',
          },
          PERSONAL_INFO: {
            navigateTo: (id: number) => `/clients/${id}`,
            route: '',
          },
          SECURITY_SETTINGS: {
            navigateTo: (id: number) => `/clients/${id}/security-settings`,
            route: 'security-settings',
          },
        },
        navigateTo: (id: number) => `/clients/${id}`,
        route: ':id',
      },
    },
    navigateTo: '/clients',
    route: 'clients',
  },
  ERROR_404: {
    navigateTo: '/error-404',
    route: 'error-404',
  },
  FORGOT_PASSWORD: {
    navigateTo: '/auth/forgot-password',
    route: 'forgot-password',
  },
  LOGIN: {
    navigateTo: 'auth/login',
    route: 'login',
  },
  LOGOUT: {
    navigateTo: '/logout',
    route: 'logout',
  },
  SERVICES: {
    children: {
      CREATE: {
        navigateTo: '/services/create',
        route: 'create',
      },
      EDIT: {
        children: {
          APPOINTMENTS: {
            navigateTo: (id: number) => `/services/${id}/appointments`,
            route: 'appointments',
          },
          PERSONAL_INFO: {
            navigateTo: (id: number) => `/services/${id}`,
            route: '',
          },
        },
        navigateTo: (id: number) => `/services/${id}`,
        route: ':id',
      },
    },
    navigateTo: '/services',
    route: 'services',
  },
  SETTINGS: {
    children: {
      GENERAL: {
        navigateTo: '/settings/general',
        route: 'general',
      },
      PROFILE: {
        navigateTo: '/settings/profile',
        route: 'profile',
      },
    },
    navigateTo: '/settings',
    route: 'settings',
  },
};

export const AppointmentFormBarbersClientsAndServicesQueryVariables = {
  orderByService: [
    {
      orderNo: {
        sort: SortOrder.Asc,
      },
    },
    {
      name: SortOrder.Asc,
    },
  ],
  whereBarber: {
    active: {
      equals: true,
    },
  },
  whereService: {
    active: {
      equals: true,
    },
  },
};

export const PHONE_PREFIX = '+385';
