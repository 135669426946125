import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import React, { memo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import NotRequireAuth from '@app/components/router/NotRequireAuth';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import AppointmentsPage from '@app/pages/AppointmentsPages/AppointmentsPage';
import BarbersPage from '@app/pages/BarberPages/BarbersPage';
import ClientsPage from '@app/pages/ClientsPages/ClientsPage';
import ServicesPage from '@app/pages/ServicePages/ServicesPage';
import SettingsGeneralPage from '@app/pages/SettingsPages/Settings';
import SettingsProfilePage from '@app/pages/SettingsPages/Profile';
import { ROUTES } from '@app/utils/constants';
import AdditionalServicesPage from '@app/pages/AdditionalServicePage/AdditionalServicesPage';
import { Back } from '../common/Back/Back';

const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));

const BarbersCreatePage = React.lazy(() => import('@app/pages/BarberPages/BarbersCreatePage'));
const BarbersEditPage = React.lazy(() => import('@app/pages/BarberPages/BarbersEditPage'));
const ServicesCreatePage = React.lazy(() => import('@app/pages/ServicePages/ServicesCreatePage'));
const ServicesEditPage = React.lazy(() => import('@app/pages/ServicePages/ServicesEditPage'));
const ClientsCreatePage = React.lazy(() => import('@app/pages/ClientsPages/ClientsCreatePage'));
const BarberWorkTimesPage = React.lazy(() => import('@app/pages/BarberPages/BarbersEditPage/BarberWorkTimes'));
const BarberWorkTimesOverridesPage = React.lazy(
  () => import('@app/pages/BarberPages/BarbersEditPage/BarberWorkTimesOverrides'),
);
const BarberPersonalInfoPage = React.lazy(() => import('@app/pages/BarberPages/BarbersEditPage/BarberPersonalInfo'));
const BarberSecuritySettingsPage = React.lazy(() => import('@app/pages/BarberPages/BarbersEditPage/SecuritySettings'));
const BarberServicesPage = React.lazy(() => import('@app/pages/BarberPages/BarbersEditPage/BarberServices'));
const ClientsEditPage = React.lazy(() => import('@app/pages/ClientsPages/ClientsEditPage/ClientsEditPage'));
const ClientPersonalInfoPage = React.lazy(
  () => import('@app/pages/ClientsPages/ClientsEditPage/ClientPersonalnfo/ClientPersonalInfo'),
);
const ClientFavouritesPage = React.lazy(() => import('@app/pages/ClientsPages/ClientsEditPage/Favourites'));
const ClientSecuritySettingsPage = React.lazy(() => import('@app/pages/ClientsPages/ClientsEditPage/SecuritySettings'));
const ClientPenaltyPage = React.lazy(() => import('@app/pages/ClientsPages/ClientsEditPage/Penalty'));
const ClientTerminsPage = React.lazy(() => import('@app/pages/ClientsPages/ClientsEditPage/ClientTermins'));
const LoginPage = React.lazy(() => import('@app/pages/LoginPage/LoginPage'));
const Logout = React.lazy(() => import('./Logout'));
const AdditionalServicesEditPage = React.lazy(
  () => import('@app/pages/AdditionalServicePage/AdditionalServiceEditPage/AdditionalServiceEditPage'),
);
const AdditionalServicesCreatePage = React.lazy(
  () => import('@app/pages/AdditionalServicePage/AdditionalServiceCreatePage/AdditionalServiceCreatePage'),
);

const Barbers = withLoading(BarbersPage);
const Services = withLoading(ServicesPage);
const AdditionalServices = withLoading(AdditionalServicesPage);
const Clients = withLoading(ClientsPage);

const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const BarbersCreatePageFallback = withLoading(BarbersCreatePage);
const BarbersEditPageFallback = withLoading(BarbersEditPage);
const ServicesCreatePageFallback = withLoading(ServicesCreatePage);
const ServicesEditPageFallback = withLoading(ServicesEditPage);
const ClientsCreatePageFallback = withLoading(ClientsCreatePage);
const BarberWorkTimesPageFallback = withLoading(BarberWorkTimesPage);
const BarberWorkTimesOverridesPageFallback = withLoading(BarberWorkTimesOverridesPage);
const BarberPersonalInfoPageFallback = withLoading(BarberPersonalInfoPage);
const BarberSecuritySettingsPageFallback = withLoading(BarberSecuritySettingsPage);
const BarberServicesPageFallback = withLoading(BarberServicesPage);
const ClientsEditPageFallback = withLoading(ClientsEditPage);
const ClientPersonalInfoPageFallback = withLoading(ClientPersonalInfoPage);
const ClientFavouritesPageFallback = withLoading(ClientFavouritesPage);
const ClientTerminsPageFallback = withLoading(ClientTerminsPage);
const ClientSecuritySettingsPageFallback = withLoading(ClientSecuritySettingsPage);
const ClientPenaltyPageFallback = withLoading(ClientPenaltyPage);
const AdditionalServicesEditPageFallback = withLoading(AdditionalServicesEditPage);
const AdditionalServicesCreatePageFallback = withLoading(AdditionalServicesCreatePage);

const protectedLayout = (
  <RequireAuth>
    <MainLayout />
  </RequireAuth>
);
const authLayout = (
  <NotRequireAuth>
    <AuthLayoutFallback />
  </NotRequireAuth>
);
export const AppRouter: React.FC = memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.APPOINTMENTS.route} element={protectedLayout}>
          <Route index path={ROUTES.APPOINTMENTS.route} element={<AppointmentsPage />} />

          <Route path={ROUTES.BARBERS.route} element={<Back />}>
            <Route index element={<Barbers />} />
            <Route path={ROUTES.BARBERS.children.EDIT.route} element={<BarbersEditPageFallback />}>
              <Route
                index
                path={ROUTES.BARBERS.children.EDIT.children.PERSONAL_INFO.route}
                element={<BarberPersonalInfoPageFallback />}
              ></Route>
              <Route
                path={ROUTES.BARBERS.children.EDIT.children.SECURITY_SETTINGS.route}
                element={<BarberSecuritySettingsPageFallback />}
              ></Route>
              <Route
                path={ROUTES.BARBERS.children.EDIT.children.SERVICES.route}
                element={<BarberServicesPageFallback />}
              ></Route>
              <Route
                path={ROUTES.BARBERS.children.EDIT.children.WORK_TIMES.route}
                element={<BarberWorkTimesPageFallback />}
              ></Route>
              <Route
                path={ROUTES.BARBERS.children.EDIT.children.WORK_TIMES_OVERRIDES.route}
                element={<BarberWorkTimesOverridesPageFallback />}
              ></Route>
            </Route>
            <Route path={ROUTES.BARBERS.children.CREATE.route} element={<BarbersCreatePageFallback />} />
          </Route>

          <Route path={ROUTES.SERVICES.route} element={<Back />}>
            <Route index element={<Services />} />
            <Route path={ROUTES.SERVICES.children.EDIT.route} element={<ServicesEditPageFallback />}></Route>
            <Route path={ROUTES.SERVICES.children.CREATE.route} element={<ServicesCreatePageFallback />} />
          </Route>
          <Route path={ROUTES.ADDITIONAL_SERVICES.route} element={<Back />}>
            <Route index element={<AdditionalServices />} />
            <Route
              path={ROUTES.ADDITIONAL_SERVICES.children.EDIT.route}
              element={<AdditionalServicesEditPageFallback />}
            ></Route>
            <Route
              path={ROUTES.ADDITIONAL_SERVICES.children.CREATE.route}
              element={<AdditionalServicesCreatePageFallback />}
            ></Route>
          </Route>
          <Route path={ROUTES.CLIENTS.route} element={<Back />}>
            <Route index element={<Clients />} />
            <Route path={ROUTES.CLIENTS.children.EDIT.route} element={<ClientsEditPageFallback />}>
              <Route
                path={ROUTES.CLIENTS.children.EDIT.children.PERSONAL_INFO.route}
                element={<ClientPersonalInfoPageFallback />}
              ></Route>
              <Route
                path={ROUTES.CLIENTS.children.EDIT.children.APPOINTMENTS.route}
                element={<ClientTerminsPageFallback />}
              ></Route>
              <Route
                path={ROUTES.CLIENTS.children.EDIT.children.FAVOURITES.route}
                element={<ClientFavouritesPageFallback />}
              ></Route>
              <Route
                path={ROUTES.CLIENTS.children.EDIT.children.PENALTY.route}
                element={<ClientPenaltyPageFallback />}
              ></Route>
              <Route
                path={ROUTES.CLIENTS.children.EDIT.children.SECURITY_SETTINGS.route}
                element={<ClientSecuritySettingsPageFallback />}
              ></Route>
            </Route>
            <Route path={ROUTES.CLIENTS.children.CREATE.route} element={<ClientsCreatePageFallback />} />
          </Route>

          <Route path={ROUTES.SETTINGS.route} element={<Back />}>
            <Route path={ROUTES.SETTINGS.children.GENERAL.route} element={<SettingsGeneralPage />}></Route>
            <Route path={ROUTES.SETTINGS.children.PROFILE.route} element={<SettingsProfilePage />}></Route>
          </Route>

          <Route path={ROUTES.ERROR_404.route} element={<Error404 />} />
        </Route>

        <Route path="/auth" element={authLayout}>
          <Route path={ROUTES.LOGIN.route} element={<LoginPage />} />
          <Route path={ROUTES.FORGOT_PASSWORD.route} element={<ForgotPasswordPage />} />
        </Route>
        <Route path={ROUTES.LOGOUT.route} element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
});
