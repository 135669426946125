import { AppointmentFormBarbersServicesAndClientsQuery } from '@app/graphql/generated';

export const getClientLabel = (client: AppointmentFormBarbersServicesAndClientsQuery['clients'][0]) => {
  const fullName = [client.firstName, client.lastName].filter(Boolean).join(' ');
  const nickname = client.name ? `(${client.name})` : '';
  const displayParts = [fullName || nickname.slice(1, -1) || 'Unknown', client.phone].filter(Boolean);

  return {
    label: displayParts.join(' - '),
    value: client.id,
  };
};

export const sortClients = (clients: { label: string; value: number }[]) => {
  return [...clients].sort((a, b) => a.label.localeCompare(b.label));
};
