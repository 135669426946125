import { useCallback, useState } from 'react';
import { ChangedDragNDropAppointment, DragNDropChanges, DragNDropDifferences } from '../Appointments.types';
import {
  AppointmentFormBarbersServicesAndClientsQuery,
  AppointmentsDateChangeQuery,
  useUpdateAppointmentMutation,
} from '@app/graphql/generated';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@app/utils/constants';

export const useDragNDrop = (
  appointments: AppointmentsDateChangeQuery['appointments'] | undefined,
  barbers: AppointmentFormBarbersServicesAndClientsQuery['barbers'] | undefined,
) => {
  const { t } = useTranslation();

  const [dragNDropChangesModalVisible, setDragNDropChangesModalVisible] = useState(false);
  const [dragNDropDiffs, setDragNDropDiffs] = useState<DragNDropChanges>();

  const cancelDragNDropChangesModal = useCallback(() => {
    setDragNDropChangesModalVisible(false);
    setDragNDropDiffs(undefined);
  }, []);

  const [updateAppointment] = useUpdateAppointmentMutation({
    onCompleted(data) {
      if (data?.updateOneAppointment?.id) {
        notificationController.success({ message: t('common.success') });
        setDragNDropChangesModalVisible(false);
        setDragNDropDiffs(undefined);
      } else {
        notificationController.error({ message: t('common.error') });
      }
    },
  });

  const updateAcceptedDragNDropChanges = useCallback(async () => {
    if (dragNDropDiffs) {
      await updateAppointment({
        variables: {
          data: {
            ...(dragNDropDiffs.changedFields.barberId
              ? {
                  barber: {
                    connect: { id: dragNDropDiffs.changedFields.barberId },
                  },
                }
              : null),
            ...(dragNDropDiffs.changedFields.endDate
              ? {
                  end: { set: dragNDropDiffs.changedFields.endDate },
                }
              : null),
            ...(dragNDropDiffs.changedFields.startDate
              ? {
                  start: { set: dragNDropDiffs.changedFields.startDate },
                }
              : null),
          },
          where: {
            id: dragNDropDiffs.appointmentId,
          },
        },
      });
    }
  }, [dragNDropDiffs, updateAppointment]);

  const onOkDragNDropChangesModal = useCallback(() => {
    updateAcceptedDragNDropChanges();
  }, [updateAcceptedDragNDropChanges]);

  const generateDifferencesOnDragNDrop = useCallback(
    (changed: ChangedDragNDropAppointment) => {
      const changedFields = changed;
      for (const id in changedFields) {
        const originalAppointment = appointments?.find((appointment) => appointment.id === Number(id));

        if (originalAppointment) {
          const differences: DragNDropDifferences = [];

          for (const key in changedFields[id]) {
            if (key === 'barberId') {
              const barberId = changedFields[id][key];
              const changedBarber = barbers?.find((barber) => barber.id === barberId);
              if (changedBarber) {
                const fieldChanged = changedBarber.id !== originalAppointment.barber.id;

                differences.push({
                  fieldChanged,
                  key,
                  newValue: changedBarber.name,
                  previousValue: originalAppointment.barber.name,
                  property: t('appointment.changedModal.propertyName.frizer'),
                });
              }
            } else if (key === 'startDate') {
              const startDate = changedFields[id][key];
              const changedStartDate = dayjs(startDate).format(DATE_TIME_FORMAT);
              const originalStartDate = dayjs(originalAppointment.start).format(DATE_TIME_FORMAT);
              const fieldChanged = changedStartDate !== originalStartDate;

              differences.push({
                fieldChanged,
                key,
                newValue: changedStartDate,
                previousValue: originalStartDate,
                property: t('appointment.changedModal.propertyName.početakTermina'),
              });
            } else if (key === 'endDate') {
              const endDate = changedFields[id][key];
              const changedEndDate = dayjs(endDate).format(DATE_TIME_FORMAT);
              const originalEndDate = dayjs(originalAppointment.end).format(DATE_TIME_FORMAT);
              const fieldChanged = changedEndDate !== originalEndDate;

              differences.push({
                fieldChanged,
                key,
                newValue: changedEndDate,
                previousValue: originalEndDate,
                property: t('appointment.changedModal.propertyName.krajTermina'),
              });
            }
          }

          differences.push({
            fieldChanged: false,
            key: 'service',
            newValue: originalAppointment.service?.name || '',
            previousValue: originalAppointment.service?.name || '',
            property: t('appointment.changedModal.propertyName.usluga'),
          });

          differences.push({
            fieldChanged: false,
            key: 'client',
            newValue: `${originalAppointment.client?.firstName} ${originalAppointment.client?.lastName}`,
            previousValue: `${originalAppointment.client?.firstName} ${originalAppointment.client?.lastName}`,
            property: t('appointment.changedModal.propertyName.klijent'),
          });

          if (differences.filter((difference) => difference.fieldChanged).length) {
            setDragNDropDiffs({ appointmentId: Number(id), changedFields: changedFields[id], differences });
            setDragNDropChangesModalVisible(true);
          }
        }
      }
    },
    [appointments, barbers, setDragNDropChangesModalVisible, setDragNDropDiffs, t],
  );

  return {
    cancelDragNDropChangesModal,
    dragNDropChangesModalVisible,
    dragNDropDiffs,
    generateDifferencesOnDragNDrop,
    onOkDragNDropChangesModal,
  };
};
