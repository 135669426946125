import { AppointmentsDateChangeQueryVariables } from '@app/graphql/generated';
import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { BarbersFilterState } from '../Appointments.types';

interface FormVisibleState {
  filters: BarbersFilterState;
  dates: string[];
  appointmentsWhereVariables: AppointmentsDateChangeQueryVariables;
}

const generateDatesInWeek = (date: Date | string) => {
  const inputDate = moment(date);
  const startDate = inputDate.clone().startOf('isoWeek');
  const endDate = inputDate.clone().endOf('isoWeek');

  const dates = [];
  for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
    dates.push(date.toISOString());
  }

  return dates;
};

const createQueryVariables = (barbers: FormVisibleState['filters']['barbers'], dates: string[]) => {
  return {
    barberWorkTimeOverrideWhereInput: {
      barberId: { in: barbers },
      date: { in: dates },
    },
    where: {
      AND: [
        {
          OR: dates.map((dateStr) => {
            const date = moment(dateStr);
            return {
              start: {
                gte: date.startOf('day').toISOString(),
                lte: date.endOf('day').toISOString(),
              },
            };
          }),
        },
      ],
      barberId: {
        in: barbers,
      },
    },
  };
};

const defaultDates = generateDatesInWeek(new Date());

const defaultFilterBarbers = localStorage.getItem('filtersBarbers')
  ? JSON.parse(localStorage.getItem('filtersBarbers') as string)
  : [0];

const initialState: FormVisibleState = {
  appointmentsWhereVariables: createQueryVariables(defaultFilterBarbers, defaultDates),
  dates: defaultDates,
  filters: {
    barbers: defaultFilterBarbers,
  },
};

export const changeDate = createAction<PrepareAction<string[]>>('appointments/setDates', (date: Date) => {
  return {
    payload: generateDatesInWeek(date),
  };
});

export const setFilters = createAction<PrepareAction<BarbersFilterState>>(
  'appointments/setFilters',
  (filters: BarbersFilterState) => {
    return {
      payload: filters,
    };
  },
);

export const appointmentFormSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(changeDate, (state, action) => {
      state.dates = action.payload;
      state.appointmentsWhereVariables = createQueryVariables(state.filters.barbers, action.payload);
    });
    builder.addCase(setFilters, (state, action) => {
      state.filters = action.payload;
      state.appointmentsWhereVariables = createQueryVariables(action.payload.barbers, state.dates);
      localStorage.setItem('filtersBarbers', JSON.stringify(action.payload.barbers));
    });
  },
  initialState,
  name: 'formVisible',
  reducers: {},
});

export default appointmentFormSlice.reducer;
