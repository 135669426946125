import { FilterOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { Select, SelectProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { setFilters } from '../../store/appointmentsSlice';

const BarbersFilter: React.FC = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state) => state.appointments.filters);
  const barberOptions = useAppSelector((state) => state.appointmentForm.barberOptions);

  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    setVisible((previousVisible) => !previousVisible);
  };

  const Filters = (
    <S
      mode="multiple"
      value={filters.barbers}
      onChange={(checkedValues) => {
        dispatch(setFilters({ barbers: checkedValues as unknown as number[] }));
      }}
      placeholder={t('appointment.filters.selectBarber')}
      options={barberOptions}
      maxTagCount={'responsive'}
    />
  );

  if (!isTablet) {
    return (
      <>
        <FiltersWrapper>
          <FilterOutlined onClick={toggleVisible} />
        </FiltersWrapper>
        <Modal
          title={t('appointment.filters.selectBarber')}
          open={visible}
          onOk={toggleVisible}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {Filters}
        </Modal>
      </>
    );
  }

  return Filters;
};

export default BarbersFilter;

const S = styled(Select)<SelectProps>`
  flex: 1;
  width: 100%;

  .ant-select-selector {
    padding: 0px 11.4px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
`;
