import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {
  AppointmentFormBarbersServicesAndClientsQuery,
  useAppointmentFormBarbersServicesAndClientsQuery,
} from '@app/graphql/generated';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getClientLabel, sortClients } from '@app/utils/client';
import { AppointmentFormBarbersClientsAndServicesQueryVariables } from '@app/utils/constants';
import { Form } from 'antd';
import { assign, cloneDeep, debounce, isEqual, omit } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setFormVisible } from '../../store/appointmentFormSlice';
import {
  AppointmentFormFields,
  AppointmentRepeatFormFields,
  FormViewModelReturnType,
  FormViewModelType,
} from './Form.types';

export const FormViewModel: FormViewModelType = (appointment, onFinish) => {
  const dispatch = useAppDispatch();

  const [clientModalVisible, setClientModalVisible] = useState<FormViewModelReturnType['clientModalVisible']>(false);
  const [form] = BaseButtonsForm.useForm<AppointmentFormFields>();
  const [serviceChanged, setServiceChanged] = useState<boolean>(false);
  const storeClientOptions = useAppSelector((state) => state.appointmentForm.clientOptions);
  const [clientOptions, setClientOptions] = useState<FormViewModelReturnType['clientOptions']>(
    sortClients(storeClientOptions || []),
  );

  const { data: appointmentFormData } = useAppointmentFormBarbersServicesAndClientsQuery({
    fetchPolicy: 'cache-first',
    variables: AppointmentFormBarbersClientsAndServicesQueryVariables,
  });

  const [clientData, setClientData] = useState<
    AppointmentFormBarbersServicesAndClientsQuery['clients'][0] | undefined
  >();

  const clientId = Form.useWatch('clientId', form);
  const isPause = Form.useWatch('isPause', form);

  useEffect(() => {
    if (clientId) {
      setClientData(appointmentFormData?.clients.find((client) => client.id === clientId));
    }
  }, [appointmentFormData?.clients, clientId, setClientData]);

  const debounceFetchClients: FormViewModelReturnType['debounceFetchClients'] = useMemo(() => {
    const loadOptions = async (searchValue: string) => {
      if (!searchValue) {
        setClientOptions(storeClientOptions);
        return;
      }

      const searchTerms = searchValue
        .toLowerCase()
        .split(' ')
        .filter((term) => term.length > 0);

      const clients: FormViewModelReturnType['clientOptions'] = [];

      appointmentFormData?.clients.forEach((client) => {
        const fullName = `${client.firstName || ''} ${client.lastName || ''}`.toLowerCase();
        const reversedFullName = `${client.lastName || ''} ${client.firstName || ''}`.toLowerCase();
        const name = client.name?.toLowerCase() || '';
        const phone = client.phone;

        // Check if all search terms are found in any of the name combinations
        const matchesAllTerms = searchTerms.every(
          (term) =>
            fullName.includes(term) || reversedFullName.includes(term) || name.includes(term) || phone.includes(term),
        );

        if (matchesAllTerms) {
          clients.push(getClientLabel(client));
        }
      });

      setClientOptions(sortClients(clients));
    };

    return debounce(loadOptions, 300);
  }, [appointmentFormData?.clients, storeClientOptions]);

  const onServiceChange: FormViewModelReturnType['onServiceChange'] = useCallback(
    (value) => {
      const service = appointmentFormData?.services.find((service) => service.id === value);
      if (service) {
        form.setFieldsValue({ price: service?.price });
        const dateRange = form.getFieldValue('dateRange');
        if (dateRange && dateRange.length > 0) {
          form.setFieldsValue({
            dateRange: [moment(dateRange[0]), moment(moment(dateRange[0]).add(service.duration, 'minutes'))],
          });
          setServiceChanged(true);
        }
      }
    },
    [form, appointmentFormData?.services],
  );

  const appointmentTime: FormViewModelReturnType['appointmentTime'] = useMemo(() => {
    return [moment(appointment.start), moment(appointment.end)];
  }, [appointment.start, appointment.end]);

  const openClientModal: FormViewModelReturnType['openClientModal'] = () => {
    setClientModalVisible(true);
  };

  const closeClientModal: FormViewModelReturnType['closeClientModal'] = () => {
    setClientModalVisible(false);
  };

  const onFormFinish: FormViewModelReturnType['onFormFinish'] = useCallback(
    async (name, info) => {
      const values = info.values as AppointmentFormFields;
      const withoutDateRange = omit(cloneDeep(values), ['dateRange']);
      const appointmentCopy = assign(
        {
          end: values.dateRange[1],
          start: values.dateRange[0],
        },
        withoutDateRange,
      );

      if (isEqual(omit(appointment, ['id']), appointmentCopy)) {
        dispatch(setFormVisible(false));
        return;
      }

      const repeatableFormfields = info.forms.repeatableForm?.getFieldsValue([
        'repeated',
        'repeatEndDate',
        'repeatOption',
        'repeatFrequency',
        'customRepeatWeekDays',
      ]) as AppointmentRepeatFormFields | undefined;

      onFinish(
        {
          barberId: values.barberId,
          barberNote: values.barberNote,
          clientId: values.clientId,
          end: values.dateRange[1]
            .set({
              milliseconds: 0,
              seconds: 0,
            })
            .toISOString(),
          id: appointment.id,
          isPause: values.isPause,
          phoneContact: values.phoneContact || '',
          price: values.price,
          serviceId: values.serviceId,
          start: values.dateRange[0]
            .set({
              milliseconds: 0,
              seconds: 0,
            })
            .toISOString(),
        },
        form.resetFields,
        repeatableFormfields,
      );
    },
    [appointment, dispatch, form.resetFields, onFinish],
  );

  const serviceId = Form.useWatch('serviceId', form);

  // set serviceChanged to true when service is changed and after 5 seconds change it to false
  useEffect(() => {
    if (serviceId) {
      setTimeout(() => {
        setServiceChanged(false);
      }, 7000);
    }
  }, [serviceId]);

  const response = useMemo(
    () => ({
      appointmentTime,
      clientData,
      clientModalVisible,
      clientOptions,
      closeClientModal,
      debounceFetchClients,
      form,
      isPause,
      onFormFinish,
      onServiceChange,
      openClientModal,
      serviceChanged,
    }),
    [
      appointmentTime,
      clientModalVisible,
      clientOptions,
      debounceFetchClients,
      form,
      isPause,
      onFormFinish,
      onServiceChange,
      serviceChanged,
      clientData,
    ],
  );

  return response;
};
